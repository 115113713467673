import { FirmType, SettingEntityType } from 'api-infrastructure';
import { FirmType as ProposalFirmType, ProposalCaseType } from 'api-proposal';

export const firmTypeToSettingEntityTypeMap: Map<FirmType, SettingEntityType> = new Map([
  [FirmType.DtccDistributor, SettingEntityType.DtccDistributor],
  [FirmType.Imo, SettingEntityType.Imo],
  [FirmType.Oid, SettingEntityType.Oid]
]);

export const firmTypeToProposalFirmTypeMap: Map<FirmType, ProposalFirmType> = new Map([
  [FirmType.DtccDistributor, ProposalFirmType.DtccDistributor],
  [FirmType.Imo, ProposalFirmType.Imo],
  [FirmType.Oid, ProposalFirmType.Oid]
]);

export const firmTypeToProposalCaseTypeMap: Map<FirmType, ProposalCaseType> = new Map([
  [FirmType.Imo, ProposalCaseType.Imo],
  [FirmType.Oid, ProposalCaseType.Oid]
]);
